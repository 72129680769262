<template>
  <el-row class="movie-list">
    <el-col :md="16" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>上传图片文件</span>
        </div>
        <div class="text item">
          <el-upload
            :action="imgOssUrl"
            :headers="imgHeaders"
            :data="imgData"
            :file-list="uploadImages"
            :multiple="true"
            :limit="40"
            :with-credentials="false"
            list-type="picture-card"
            :before-upload="handleBeforeUpload"
            :on-success="handleOnSuccess"
            :on-error="handleOnError"
            :on-remove="handleOnRemove"
            :on-preview="handleOnPreview"
          >
            <i class="el-icon-plus" />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>
      </el-card>
    </el-col>
    <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>稿件信息</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="onSubmit('submitForm')">发布</el-button>
        </div>
        <div class="text item">
          <el-form ref="submitForm" :model="submitForm" :rules="submitFormRules" label-width="80px">
            <el-form-item label="相册名">
              <el-input v-model="submitForm.albumName" style="width: 70%; padding-right: 2px" placeholder="相册名不能超过 50 个字符" />
            </el-form-item>
            <el-form-item label="可见范围">
              <el-select v-model="submitForm.scope" placeholder="选择稿件的可见范围">
                <el-option label="本人可见" value="1" />
                <el-option label="所有人可见" value="2" />
                <el-option label="VIP 可见" value="3" />
                <el-option label="验证码可见" value="4" />
              </el-select>
            </el-form-item>
            <el-form-item label="定时发布">
              <el-date-picker
                v-model="submitForm.scheduledPubDate"
                type="datetime"
                placeholder="选择定时发布的时间"
              />
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { submitAlbum } from '@/api/image'
import { getPhotoChannelInfo } from '@/api/file'

var imageFileMap = new Map()
export default {
  name: 'PublishImage',
  components: {},
  data() {
    return {
      imgOssUrl: '',
      // ****************************************************************************************************************
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelId: 105
      },
      dialogImageUrl: '',
      dialogVisible: false,
      uploadImages: [],
      // ****************************************************************************************************************
      submitForm: {
        imageFileIds: [],
        albumName: null,
        channelId: 105,
        scope: '2',
        scheduledPubDate: null
      },
      submitFormRules: {
        imageFileIds: [
          { type: 'array', required: true, message: '至少上传一张图片', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    getPhotoChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.imgOssUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传图片',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: '获取 OSS 服务器地址失败, 暂时无法上传图片',
        type: 'warning',
        duration: 3000
      })
    })
  },
  methods: {
    // ****************************************************************************************************************
    handleBeforeUpload(file) {
      // const fileType = file.type
      var isJPG = false
      if (file.type === 'image/jpeg' || file.type === 'image/webp' ||
        file.type === 'image/gif' || file.type === 'image/png') {
        isJPG = true
      }

      const isLt2M = file.size / 1024 / 1024 < 100
      if (!isJPG) {
        this.$message.error('图片只能是 jpeg/webp/gif/png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 100MB!')
      }
      return isJPG && isLt2M
    },
    handleOnSuccess(res, file) {
      if (res.code === 0) {
        const resData = res.data
        imageFileMap.set(file.name, resData.uploadId)
      } else {
        this.$notify({
          title: '提示',
          message: '图片上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    handleOnError(err, file, fileList) {
      const errMsg = JSON.parse(err.message)
      this.$notify({
        title: '图片上传失败',
        message: errMsg.msg,
        type: 'error',
        duration: 3000
      })
    },
    handleOnRemove(file, fileList) {
      imageFileMap.delete(file.name)
    },
    handleOnPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // ****************************************************************************************************************
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false
        this.submitForm.imageFileIds = Array.from(imageFileMap.values())
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}

.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
</style>
